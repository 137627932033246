import { AppManifest, InnerRoutesSortOptionsStrings } from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import { isAddUninstallMAActionEnabled, shouldEnableGeneralSettings } from '../../../../../utils/experiments';
import { MEMBERS_PAGES_PANEL_HELP_ID } from '../../../../constants';
import { getApplicationActions } from './app-actions';
import { getPageSettings } from './settings';
import { getPageActions } from './page-actions';

const sortInnerRoutes: InnerRoutesSortOptionsStrings = 'no-sort';

export const getPagesManifest = async (
  t: TranslationFunction,
  hasOnlyOneApplicationPage: boolean,
): Promise<AppManifest['pages']> => {
  const showGeneralSettings = await shouldEnableGeneralSettings();
  const shouldAddUninstallMAAction = await isAddUninstallMAActionEnabled();
  const shouldShowReadOnlyPageActions = shouldAddUninstallMAAction && hasOnlyOneApplicationPage;

  return {
    sortInnerRoutes,
    pageDescriptors: {
      default: { icon: 'membersAction' },
    },
    applicationSettings: {
      default: {
        displayName: t('Pages_Panel_MemberMenu_Title'),
        helpId: MEMBERS_PAGES_PANEL_HELP_ID,
      },
    },
    applicationActions: getApplicationActions(t, showGeneralSettings),
    pageSettings: getPageSettings(),
    pageActions: getPageActions(t, shouldShowReadOnlyPageActions),
  };
};
