import { EditorSDK } from '@wix/platform-editor-sdk';

import enforceSequentiality from '../../enforceSequentiality';
import { ProfileType } from '../../services/profile';
import { MembersAreaPublicApi } from '../types';
import { registerMembersAreaApps, installRegisteredApps } from './integration';

export const createPublicAPI = (editorSDK: EditorSDK): MembersAreaPublicApi => {
  return {
    getRegisteredApps: () => {
      return Promise.resolve({});
    },
    registerMembersAreaApps: (applications, verticalAppDefId, applicationsOptions) => {
      const action = () => registerMembersAreaApps(editorSDK, applications, verticalAppDefId, applicationsOptions);
      return enforceSequentiality('editorAPI.registerMembersAreaApps', action);
    },
    installRegisteredApps: (verticalAppDefId) => {
      const action = () => installRegisteredApps(editorSDK, verticalAppDefId);
      return enforceSequentiality('editorAPI.installRegisteredApps', action);
    },
    addApplications: async (applications, shouldNavigate, options) => {
      console.log({ applications, shouldNavigate, options });
      return Promise.resolve();
    },
    getMembersPageRef: (page) => {
      return Promise.resolve(undefined);
    },
    removeMembersAreaPage: (pageId, appDefinitionId) => {
      return Promise.resolve();
    },
    setHorizontalLayout: (pwHeight) => {
      return Promise.resolve();
    },
    setSidebarLayout: () => {
      return Promise.resolve();
    },

    handleVerticalDeletion: (verticalAppDefId) => {
      return Promise.resolve();
    },
    addCustomPage: (isPrivate) => {
      return Promise.resolve();
    },
    refreshPageState: () => {
      return Promise.resolve();
    },
    getProfileType: () => {
      return Promise.resolve(ProfileType.ALL);
    },
    setProfileType: (profileType) => {
      return Promise.resolve();
    },
    refreshRouters: () => {
      return Promise.resolve();
    },
    refreshMembersAreaApps: () => {
      return Promise.resolve();
    },
    setProfileWidgetHeight: (height: number) => {
      return Promise.resolve();
    },
    configureMAApps: (configurationDetails) => {
      return Promise.resolve();
    },
    configureMAAppsAndOpenManagePages: (configurationDetails, referralInfo) => {
      return Promise.resolve();
    },
    openGeneralSettingsPanel: (referralInfo) => {
      return Promise.resolve();
    },
    getMemberPrivacySettings: () => {
      return Promise.resolve(undefined);
    },
    _getIsResponsiveEditor: () => {
      return Promise.resolve(true);
    },
    registerAdditionalWidgets: () => {},
    getAdditionalWidgets: () => {},
    installAdditionalWidgets: () => {},
  };
};
