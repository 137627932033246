import { AppActionClicked as AppActionClickedEvent, EventType } from '@wix/platform-editor-sdk';

import { openRemovePagePanel, openUninstallPanel } from '../../../wrappers/platformPanels';
import {
  openGeneralSettingsPanel,
  openManagePagesPanel,
  openMemberPrivacySettingsDashboard,
} from '../../../wrappers/panels';
import { MembersAreaEvent, MembersAreaOnEventFn } from '../../types';
import { handleAppManagerEvent } from './apps-manager';

export const onEvent: MembersAreaOnEventFn = async (event, editorSDK) => {
  const { eventType, eventPayload } = event;

  switch (eventType) {
    // Platform Events
    case EventType.appActionClicked:
      return handleAppManagerEvent(editorSDK, event as AppActionClickedEvent);

    // Members Area Events
    case MembersAreaEvent.CreateBlankPage:
      return openManagePagesPanel({ eventPayload, editorSDK });
    case MembersAreaEvent.RemovePage:
      return openRemovePagePanel(editorSDK, eventPayload.pageRef);
    case MembersAreaEvent.Uninstall:
      return openUninstallPanel(editorSDK);
    case MembersAreaEvent.GeneralSettings:
      return openGeneralSettingsPanel({ eventPayload, editorSDK });
    case MembersAreaEvent.MemberPrivacyDashboard:
      return openMemberPrivacySettingsDashboard(editorSDK);
    default:
      throw new Error(`[MA] ${eventType} is not implemented`);
  }
};
