import { IntegrationApplication } from '@wix/members-area-integration-kit';

import { ProfileType } from '../editor-app-module/services/profile';

export enum PageType {
  StandAlone = 'stand-alone',
  Custom = 'custom',
  TPA = 'tpa',
}

export interface PageToModifyModify {
  pageType: PageType;
  integrationApplication: IntegrationApplication;
}

export type UpdatableProfileType = ProfileType.BWP_ALL | ProfileType.BWP_ONLY;

export interface PagesModificationPayload {
  appsToInstall: PageToModifyModify[];
  appsToRemove: PageToModifyModify[];
  profilePageType?: UpdatableProfileType | null;
}
