import { GetAppManifest } from '@wix/platform-editor-sdk';

import { getTranslationFunction } from '../../../../i18n';
import { getApplicationPages } from '../../../wrappers/pages';
import { getAppDescriptor } from './app-descriptor';
import { getPagesManifest } from './page-manifest';
import { getControllersStageDataManifest } from './controllers-stage-data-manifest';

export const getAppManifest: GetAppManifest<unknown> = async (_, editorSDK) => {
  const t = await getTranslationFunction(editorSDK, true);
  const applicationPages = await getApplicationPages({ editorSDK });
  const hasOnlyOneApplicationPage = applicationPages.length === 1;

  return {
    appDescriptor: await getAppDescriptor(t),
    pages: await getPagesManifest(t, hasOnlyOneApplicationPage),
    controllersStageData: getControllersStageDataManifest(t),
  };
};
