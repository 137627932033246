import { EditorSDK } from '@wix/platform-editor-sdk';
import { IntegrationApplication, MembersAreaAppsOptions } from '@wix/members-area-app-definitions';
import { getAppDefinitions } from '@wix/members-area-app-definitions/dist/esm/getAppDefinition';
import { createInstance } from 'i18next';

import { getUniquePages } from '../../../../utils/pages';
import * as state from '../../../services/applicationState';
import { createBIService } from '../../../../utils/bi';
import { MembersAreaPagePublicApi } from '../../types';
import { APP_TOKEN, PROFILE_PAGE_BOB_APP_DEF_ID } from '../../../constants';

const getMembersAreaPagePublicApi = async (editorSDK: EditorSDK) => {
  const membersAreaPagePublicApi = await editorSDK.application.getPublicAPI(APP_TOKEN, {
    appDefinitionId: PROFILE_PAGE_BOB_APP_DEF_ID,
  });
  return membersAreaPagePublicApi as MembersAreaPagePublicApi | undefined;
};

export const registerMembersAreaApps = async (
  editorSDK: EditorSDK,
  applications: IntegrationApplication[],
  verticalAppDefId: string,
  applicationsOptions: MembersAreaAppsOptions,
) => {
  const applicationDefinitions = await getAppDefinitions({
    i18next: createInstance(),
    applications,
    editorSDK,
    applicationsOptions,
  });
  const currentIntegratedAppsMap = state.getAllIntegratedApps();
  const verticalsApps = getUniquePages([
    ...(currentIntegratedAppsMap[verticalAppDefId] ?? []),
    ...applicationDefinitions,
  ]);

  state.setIntegratedApps({
    ...currentIntegratedAppsMap,
    [verticalAppDefId]: verticalsApps,
  });

  // TODO: resetRolesOnSocialVertical alternative
};

// TODO: Add widgetId to integration kit
const widgetPluginsMap: { [pageId in string]: string } = {
  about: '14dbefb9-3b7b-c4e9-53e8-766defd30587',
  'My Rewards': 'c85a2896-9f7c-4cf1-9bf8-95852cc5219a',
  'member-posts-page': '14f0266c-e0a3-afc6-d07c-5be8e20949cd',
  'member-comments-page': '14f51b00-3ada-272e-b6db-34d5da9dd623',
};

export const installRegisteredApps = async (editorSDK: EditorSDK, verticalAppDefId: string) => {
  const biService = await createBIService({ editorSDK });
  biService.verticalTriggeredMaInstallInitiated({ originAppId: verticalAppDefId });

  const integrationApps = state.getVerticalsApps(verticalAppDefId) as IntegrationApplication[];
  const integrationAppsToInstall = integrationApps.filter(
    (app) => app.shouldInstallInitially !== false && widgetPluginsMap[app.pageId],
  );

  if (integrationAppsToInstall.length > 0) {
    const membersAreaPagePublicApi = await getMembersAreaPagePublicApi(editorSDK);
    const widgetPlugins = integrationAppsToInstall.map((definition) => ({
      appDefinitionId: definition.appDefinitionId,
      widgetId: widgetPluginsMap[definition.pageId],
    }));
    await membersAreaPagePublicApi?.addWidgetsPlugins(widgetPlugins);
  }

  // TODO: Set BWP on install

  biService.verticalTriggeredMaInstallSuccess({ originAppId: verticalAppDefId });
};
