import {
  EditorSDK,
  InnerRoutesSortOptionsStrings,
  AppManifest,
  ApplicationAction,
  GetAppManifest,
} from '@wix/platform-editor-sdk';
import { TranslationFunction } from 'i18next';

import {
  isAddUninstallMAActionEnabled,
  shouldAddOpenMembersAccountBmActionToMyBusinessPanel,
  shouldEnableGeneralSettings,
} from '../../../../utils/experiments';
import { getTranslationFunction } from '../../../../i18n';
import { MEMBERS_MENU_CONTROLLER_TYPE } from '../../../constants/layouts/menus/common-menu-layouts';
import { LOGIN_BAR_CONTROLLER_TYPE } from '../../../constants/layouts/login-bar/classic-login-bar-layouts';
import * as constants from '../../../constants';
import * as actions from './actions';
import * as pages from './pages';

const getRouterActions = (t: TranslationFunction, showGeneralSettings: boolean) => {
  return {
    showOnAllModes: true,
    default: [
      showGeneralSettings && actions.getOpenGeneralSettingsAction(t),
      actions.getAddPageAction(t),
      actions.VIEW_SITE_MEMBERS,
      showGeneralSettings && actions.getOpenMemberPrivacyDashboardAction(t),
      actions.OPEN_SIGNUP_SETTINGS,
      actions.getAdvancedURLSettingsAction(t),
      actions.getDeleteMembersAreaAction(t),
    ].filter((a) => !!a) as ApplicationAction[],
  };
};

const defaultInnerRoutesSortOption: InnerRoutesSortOptionsStrings = 'no-sort';

const buildAppManifest = async (editorSDK: EditorSDK, t: TranslationFunction): Promise<AppManifest> => {
  const shouldAddUninstallMAAction = await isAddUninstallMAActionEnabled();
  const shouldAddOpenMembersAccountBmDashboardAction = await shouldAddOpenMembersAccountBmActionToMyBusinessPanel();
  const showGeneralSettings = await shouldEnableGeneralSettings();
  const applicationPages = await editorSDK.document.pages.getApplicationPages('');
  const sortInnerRoutes = { default: defaultInnerRoutesSortOption };
  const addUninstallMAAction =
    shouldAddUninstallMAAction && applicationPages.length === 1 ? [actions.getDeleteMembersAreaAction(t)] : [];
  const addOpenMembersAccountBmAction = shouldAddOpenMembersAccountBmDashboardAction
    ? [
        {
          title: t('Apps_Manager_Customize_Members_Profiles'),
          actionId: 'openMembersAccountBmDashboard',
          type: 'dashboard',
          icon: 'appManager_settingsAction',
        },
      ]
    : [];

  const appDescriptorManifest = {
    appDescriptor: {
      mainActions: [
        {
          title: t('Apps_Manager_Add_Member_Page'),
          actionId: 'addMemberPage',
          type: 'editorActions',
          icon: 'appManager_addElementsAction',
        },
      ],
      defaultActions: {
        learnMoreKB: 'b74bcbba-91c8-4951-a3de-a1dc698f6356',
      },
      customActions: [
        {
          title: t('Apps_Manager_Manage_Member_Pages'),
          actionId: 'openMembersAreaPagesPanel',
          type: 'editorActions',
          icon: 'appManager_pagesAction',
        },
        {
          title: t('Apps_Manager_Open_Members_Add_Panel'),
          actionId: 'openMembersAddPanel',
          type: 'editorActions',
          icon: 'appManager_addElementsAction',
        },
        ...addOpenMembersAccountBmAction,
        {
          title: t('Apps_Manager_Open_Badges_Page'),
          actionId: 'openBadgesPage',
          type: 'dashboard',
          icon: 'appManager_settingsAction',
        },
        {
          title: t('Apps_Manager_Engage_With_Your_Members'),
          actionId: 'openSiteMembersDashboard',
          type: 'dashboard',
          icon: 'appManager_settingsAction',
        },
      ],
    },
  };

  return {
    ...appDescriptorManifest,
    // https://github.com/wix-private/js-platform-editor-sdk/blob/master/docs/articles/controller-stage-data-manifest.md
    controllersStageData: {
      members: {
        default: {
          visibility: 'NEVER',
        },
      },
      [LOGIN_BAR_CONTROLLER_TYPE]: {
        default: {
          displayName: t('LoginBarAppWidget_Display_Name'),
        },
      },
      [MEMBERS_MENU_CONTROLLER_TYPE]: {
        default: {
          displayName: t('MembersMenuAppWidget_Display_Name'),
        },
      },
    },
    pages: {
      pageDescriptors: {
        default: { icon: 'membersAction' },
      },
      applicationSettings: {
        default: {
          displayName: t('Pages_Panel_MemberMenu_Title'),
          helpId: constants.MEMBERS_PAGES_PANEL_HELP_ID,
        },
      },
      applicationActions: getRouterActions(t, showGeneralSettings),
      pageSettings: {
        default: [pages.PAGE_INFO, pages.PERMISSIONS_PLATFORMISED, pages.SEO],
      },
      pageActions: {
        default: [],
        deletable: [
          {
            title: t('Pages_Panel_MemberMenu_Actions_Page_Delete'),
            icon: 'deleteAction',
            event: 'removePage',
          },
        ],
        readOnly: addUninstallMAAction,
      },
      // @ts-expect-error
      sortInnerRoutes,
    },
  };
};

export const getAppManifest: GetAppManifest<unknown> = async (_, editorSDK) => {
  const t = await getTranslationFunction(editorSDK, true);
  return buildAppManifest(editorSDK, t);
};
